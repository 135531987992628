import { ISearchRequest, SearchDocumentType } from '@wix/client-search-sdk';
import { CategorySettings } from '@wix/search-results-settings-definitions';

export const withDocumentType = (
  searchRequest: ISearchRequest,
  visibleCategories: Array<[String, CategorySettings]>,
): ISearchRequest => {
  if (
    searchRequest.documentType &&
    visibleCategories
      .map(([documentType]) => documentType)
      .includes(searchRequest.documentType)
  ) {
    return searchRequest;
  }

  const documentType = visibleCategories.length
    ? (visibleCategories[0][0] as SearchDocumentType)
    : SearchDocumentType.All;

  return {
    ...searchRequest,
    documentType,
  };
};
