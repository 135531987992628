import {
  IWidgetController,
  IControllerConfig,
  I$W,
} from '@wix/native-components-infra/dist/src/types/types';

import { createEventHandler } from '@wix/tpa-settings';

import { IWidgetControllerConfig } from './platform.types';
import { SearchResultsControllerStore } from './searchResultsControllerStore';
import { extractSettings } from './extractSettings';

export interface ISearchResultsWixCode {
  changeQuery(query: string): void;
}

export interface ISettingsEvents {
  previewStateChange: {
    shouldHaveSearchResults: boolean;
    shouldSetNonAllDocumentType: boolean;
  };
}

export async function searchResultsControllerFactory(
  controllerConfig: IWidgetControllerConfig,
): Promise<IWidgetController> {
  const categoryList = await controllerConfig.getCategoryList();
  const { settings, publicData } = extractSettings(
    controllerConfig.config,
    categoryList,
  );
  const eventHandler = createEventHandler<ISettingsEvents>(publicData);

  eventHandler.on('previewStateChange', value => {
    controllerStore.updateDemoMode(value);
  });

  eventHandler.onReset(() => {
    controllerStore.updateDemoMode({
      shouldHaveSearchResults: true,
      shouldSetNonAllDocumentType: false,
    });
  });

  let controllerStore: SearchResultsControllerStore;

  try {
    controllerStore = new SearchResultsControllerStore(
      controllerConfig,
      settings,
    );
  } catch (error) {
    controllerConfig.reportError &&
      controllerConfig.reportError(error, {
        tags: {
          ssr: controllerConfig.wixCodeApi.window.rendering.env === 'backend',
          demo: controllerConfig.wixCodeApi.window.viewMode !== 'Site',
          mobile: controllerConfig.wixCodeApi.window.formFactor === 'Mobile',
          fluid: 'unknown',
        },
      });
    throw error;
  }

  return {
    pageReady() {
      return controllerStore.setInitialState();
    },

    updateConfig($w: I$W, config: IControllerConfig) {
      const { settings, publicData } = extractSettings(config, categoryList);

      eventHandler.notify(publicData);
      void controllerStore.updateSettings(settings);
    },

    exports: () =>
      ({
        // NOTE: used for mocks in tests
        searchSDK: controllerStore.getSearchSDK(),
        changeQuery: controllerStore.changeQuery,
      } as ISearchResultsWixCode),
  };
}
