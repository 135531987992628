import {
  ISearchRequest,
  ClientSearchSDK,
  ISearchResponse,
  ISearchResponseTotals,
  ISampleResponse,
  SearchDocumentType,
  ISampleResponseSample,
  IErrorResponse,
} from '@wix/client-search-sdk';

import { CategorySettings } from '@wix/search-results-settings-definitions';

import { ISearchLocation } from '../location';
import { BiDocumentIds, SearchRequestBi } from '../bi';
import { ISearchSample } from '../searchResultsControllerStore';

const SAMPLES_SIZE = {
  MOBILE: 4,
  DESKTOP: 3,
};

type CategoryEntry = [SearchDocumentType, CategorySettings];

type SearchParams = {
  searchRequest: ISearchRequest;
  searchSDK: ClientSearchSDK;
  isMobile: boolean;
  previousQuery: string | undefined;
  previousTotals: ISearchResponseTotals | undefined;
  searchResultsAbsoluteUrl: string;
  searchLocation: ISearchLocation;
  visibleCategories: Array<[string, CategorySettings]>;
  bi: SearchRequestBi;
};

export const search = async ({
  searchRequest,
  searchSDK,
  isMobile,
  previousQuery,
  previousTotals,
  searchResultsAbsoluteUrl,
  searchLocation,
  visibleCategories,
  bi,
}: SearchParams): Promise<
  | {
      searchResponse: ISearchResponse;
      searchResponseTotals: ISearchResponseTotals;
      searchSamples: ISearchSample[];
    }
  | IErrorResponse
> => {
  try {
    bi.started();

    const shouldShowSamples =
      searchRequest.documentType === SearchDocumentType.All;

    const sortedCategories = sortCategories(visibleCategories);

    const [
      searchResponse,
      searchResponseTotals,
      searchSampleResponse,
    ] = await getSearchResponses(
      searchRequest,
      shouldShowSamples,
      searchSDK,
      isMobile,
      previousQuery,
      previousTotals || {},
    );

    if ('isError' in searchResponse) {
      return searchResponse;
    }

    if ('isError' in searchResponseTotals) {
      return searchResponseTotals;
    }

    if ('isError' in searchSampleResponse) {
      return searchSampleResponse;
    }

    if (shouldShowSamples) {
      searchResponse.totalResults =
        searchResponseTotals[SearchDocumentType.All] || 0;
    }

    const searchSamples: ISearchSample[] = searchSampleResponse.results
      .map(s => ({
        ...s,
        url: searchLocation.buildSearchResultsUrl(searchResultsAbsoluteUrl, {
          query: searchRequest.query,
          documentType: s.documentType,
        }),
      }))
      .sort(
        (s1, s2) =>
          sortedCategories.indexOf(s1.documentType) -
          sortedCategories.indexOf(s2.documentType),
      );

    bi.finished(
      searchResponse,
      searchResponseTotals,
      getDocumentIds({
        searchResponse,
        searchSamples,
        shouldShowSamples,
      }),
    );
    return { searchResponse, searchResponseTotals, searchSamples };
  } catch (error) {
    bi.failed(error);
    throw error;
  }
};

function getDocumentIds({
  searchResponse,
  searchSamples,
  shouldShowSamples,
}: {
  searchResponse: ISearchResponse;
  searchSamples: ISearchSample[];
  shouldShowSamples: boolean;
}): BiDocumentIds {
  if (shouldShowSamples) {
    return searchSamples.reduce((acc, curr) => {
      return { ...acc, [curr.documentType]: curr.documents.map(d => d.id) };
    }, {});
  }
  return searchResponse.documents.reduce((acc, curr) => {
    if (!acc[curr.documentType]) {
      acc[curr.documentType] = [];
    }
    acc[curr.documentType].push(curr.id);
    return acc;
  }, {});
}

function sortCategories(visibleCategories: Array<[string, CategorySettings]>) {
  const sortedCategories = visibleCategories
    .sort((c1: CategoryEntry, c2: CategoryEntry) => c1[1].index - c2[1].index)
    .map(c => c[0]);
  return sortedCategories;
}

function getSearchResponses(
  searchRequest: ISearchRequest,
  shouldShowSamples: boolean,
  searchSDK: ClientSearchSDK,
  isMobile: boolean,
  previousQuery: string | undefined,
  searchResponseTotals: ISearchResponseTotals,
) {
  const shouldLoadTotals = previousQuery !== searchRequest.query;

  const samplesPromise =
    shouldShowSamples || shouldLoadTotals
      ? searchSDK.getSample({
          query: searchRequest.query,
          limit: isMobile ? SAMPLES_SIZE.MOBILE : SAMPLES_SIZE.DESKTOP,
        })
      : Promise.resolve({ results: [] });

  return Promise.all<
    ISearchResponse | IErrorResponse,
    ISearchResponseTotals | IErrorResponse,
    ISampleResponse | IErrorResponse
  >([
    shouldShowSamples
      ? Promise.resolve({
          documents: [],
          documentType: SearchDocumentType.All,
          totalResults: 0,
        })
      : searchSDK.search(searchRequest),
    shouldLoadTotals
      ? samplesPromise.then(
          (samplesResponse: ISampleResponse | IErrorResponse) => {
            if ('isError' in samplesResponse) {
              return samplesResponse;
            }
            const totalResults = samplesResponse.results.reduce(
              (sum, { total }) => sum + total,
              0,
            );
            return samplesResponse.results.reduce(
              (
                totals: ISearchResponseTotals,
                { documentType, total }: ISampleResponseSample,
              ) => {
                totals[documentType] = total;
                return totals;
              },
              {
                [SearchDocumentType.All]: totalResults,
              },
            );
          },
        )
      : searchResponseTotals,
    samplesPromise,
  ]);
}
