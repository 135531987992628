import { noop } from 'lodash';

import {
  IPlatformAPI,
  IWebStorage,
} from '@wix/native-components-infra/dist/src/types/types';
import { aMemoryStorage } from '@wix/native-components-infra/dist/src/memoryStorage/memoryStorage';

import { guid } from '../../lib/guid';

export const enum BICorrelationIdKey {
  SearchSuggestions = 'BICorrelationId:SearchSuggestions',
  SearchSubmit = 'BICorrelationId:SearchSubmit',
}

export function createBICorrelationId(): string {
  return guid();
}

export class BICorrelationIdStore {
  private readonly storage: IWebStorage;
  private readonly reportError: (error: Error) => void;

  constructor(platformApi: IPlatformAPI, reportError: (error: Error) => void) {
    // NOTE: storage could be not available in some browsers
    // https://sentry.io/organizations/wix_o/issues/1140629343/
    // https://sentry.io/organizations/wix_o/issues/1145676561/
    this.storage = [
      platformApi.storage.session,
      platformApi.storage.memory,
      aMemoryStorage,
    ].find(_storage => _storage && typeof _storage.getItem === 'function') || {
      getItem: () => null,
      setItem: noop,
      removeItem: noop,
      clear: noop,
    };
    this.reportError = reportError;
  }

  set(key: BICorrelationIdKey, correlationId: string): void {
    this.storage.setItem(key, correlationId);
  }

  get(key: BICorrelationIdKey): string {
    const correlationId = this.storage.getItem(key);
    if (!correlationId) {
      this.reportError(new Error(`storage key '${key}' is not available`));
      return '';
    }
    return correlationId;
  }

  has(key: BICorrelationIdKey): boolean {
    return this.storage.getItem(key) !== null;
  }
}
